import React from 'react';
import './Styling.css';

function Projects() {
  return (
    <div className="App">
      <p>Projects</p>
    </div>
  );
}

export default Projects;
